.heading {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.column {
  border: 1px solid #00a568;
  padding: 10px 0;
}
