.title {
  width: max-content;
  background-color: #00a568;
  color: white;
  font-size: 20px;
}
.info {
  border: 1px solid #00a568;
  position: relative;
}
.iconBox {
  background-color: #00a568;
  padding: 9px;
  align-self: flex-end;
}
.icons {
  color: white;
  margin: 0 6px;
}
.icons:hover {
  color: #000;
  cursor: pointer;
}

.row {
  margin: 0;
}
.row div {
  margin: 10px auto !important;
}
@media (max-width: 426px) {
  .row {
    flex-direction: column;
  }
}
