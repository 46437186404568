 .parent {
  margin: 30px 0;
}
.input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  /* width: auto !important; */
}
.input:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media (max-width: 426px) {
  .row {
    flex-direction: column;
  }
}
