@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}
.input-group-text {
  background-color: white !important;
  border: none !important;
}
.btn-primary {
  background-color: #00a568 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #017c4f !important;
}
.btn-primary:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}
.littleton-head-bg {
  background-color: #1f243a;
  flex-wrap: nowrap;
  align-items: center;
}

.logo {
  height: 100%;
  width: 100%;
}

.logo-small {
  height: 25%;
  width: 25%;
}

@media (max-width: 426px) {
  .header {
    font-size: 17px;
  }
}
@media (max-width: 376px) {
  .header {
    font-size: 15px;
  }
}
@media (max-width: 321px) {
  .header {
    font-size: 12px;
  }
}
