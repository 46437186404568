.sidebar {
  height: 100vh;
  margin-top: 83px;
  justify-content: center;
  align-items: flex-start !important;
}
@media (max-width: 426px) {
  .sidebar {
    height: auto !important;
  }
}
