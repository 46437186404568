.spinnerDiv {
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #00a568;
  position: fixed;
  z-index: 999;
}
.spinner {
  height: 200px !important;
  width: 200px !important;
}
