.box {
  border-radius: 5px;
  background-color: #00a568;
  color: white;
  width: auto;
  margin: 3px auto;
}
.box a {
  color: white;
}
