.title {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.button {
  background-color: #00a568;
  border: none !important;
}
.button:hover {
  background-color: #017c4f;
}
.button:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}
.row {
  width: 100% !important;
}
.input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  /* width: auto !important; */
}
.input:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media (max-width: 769px) {
  .title {
    font-size: 20px;
    padding: 15px;
  }
  .row {
    flex-direction: column;
  }
}
