.col {
  /* height: 100%;  */
  padding-top: 12px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 8px 6px rgb(0 0 0 / 2%);
}
.mainRow {
  padding: 12px;
}
@media (max-width : 426px) {
  .row {
    text-align: center;
  }
  .row > div {
    margin: 10px auto;
  }
  .col {
    margin: 10px auto;
  }
  .col > h3 {
    margin: 20px auto;
    text-align: center;
  }
  .mainRow {
    padding: 0px;
  }
}
