.innerDiv {
  background-color: #ffff;
  width: 60%;
  margin: auto;
  position: relative;
  z-index: 99;
}
@media (max-width: 1025px) {
  .innerDiv {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .innerDiv {
    width: 90%;
  }
}
