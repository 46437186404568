.title {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.heading {
  background-color: #00a568;
  color: white;
  font-size: 20px;
  padding: 20px;
  width: max-content;
}
.info {
  border: 1px solid #00a568;
  position: relative;
}
/* .footer {
  position: fixed;
  bottom: 20px;
} */

@media (max-width: 769px) {
  .title {
    font-size: 20px;
    padding: 15px;
  }
}
