.container {
  margin-top: 85px;
}
.div {
  padding: 50px;
}
.textBox:focus {
  box-shadow: none;
}
@media (max-width: 768px) {
  .notification {
    width: 100%;
  }
}
