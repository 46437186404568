@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.NotificationBox_box__2Jl8d {
  border-radius: 5px;
  background-color: #00a568;
  color: white;
  width: auto;
  margin: 3px auto;
}
.NotificationBox_box__2Jl8d a {
  color: white;
}

* {
  font-family: "Roboto", sans-serif;
}
.input-group-text {
  background-color: white !important;
  border: none !important;
}
.btn-primary {
  background-color: #00a568 !important;
  border: none !important;
}
.btn-primary:hover {
  background-color: #017c4f !important;
}
.btn-primary:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}
.littleton-head-bg {
  background-color: #1f243a;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.logo {
  height: 100%;
  width: 100%;
}

.logo-small {
  height: 25%;
  width: 25%;
}

@media (max-width: 426px) {
  .header {
    font-size: 17px;
  }
}
@media (max-width: 376px) {
  .header {
    font-size: 15px;
  }
}
@media (max-width: 321px) {
  .header {
    font-size: 12px;
  }
}

.Dropdown_dropdown__3XS0X {
  /* width: 234px; */
  margin: 0 10px;
}
.Dropdown_select__3TReD {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.Dropdown_select__3TReD:disabled {
  color: #000 !important;
  background-color: lightgray !important;
  opacity: 0.5 !important;
}
.Dropdown_select__3TReD:focus {
  box-shadow: none !important;
  background-color: transparent !important;
}
@media (max-width: 769px) {
  .Dropdown_dropdown__3XS0X {
    margin: 10px;
  }
}

.FormGroup_input__2goMM {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    position: relative !important;
}
.FormGroup_input__2goMM:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}
.ValidationError_error__cNfVg {
    font-size: 12px;
    color: red;
    position: relative;
    top: 0;
    left: 15px;

}
.Student_title__3HrsT {
  width: -webkit-max-content;
  width: max-content;
  background-color: #00a568;
  color: white;
  font-size: 20px;
}
.Student_student__1sf91 {
  border-top: 1px solid #00a568;
  border-right: 1px solid #00a568;
  border-left: 1px solid #00a568;
}
.Student_input__ZKxMg {
  border-top: none !important;
  border-left: none;
  border-right: none;
  border-radius: 0;
  background-color: transparent;
  position: relative;
}
.Student_input__ZKxMg:focus {
  background-color: transparent;
  box-shadow: none;
}
.Student_datePicker__3ZvEY {
  padding: 0.375rem 0 !important;
  background-color: transparent;
}

/* --------------------------------------------------------------------------------- */

.Student_css-1480iag-MuiInputBase-root-MuiInput-root__NpDeh::after {
  border-radius: 4px !important;
  position: relative !important;
  border: 1px solid #ced4da !important;
  font-size: 16px !important;
  width: auto !important;
  padding: 10px 12px !important;

  border-bottom: 1px solid yellow !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  /* -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0); 
  -ms-transform: scaleX(0); */
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  /* -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
  transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
.Student_css-1480iag-MuiInputBase-root-MuiInput-root__NpDeh:focus {
  border-bottom: 1px solid yellow !important;
}

/* --------------------------------------------------------------------------------------- */
.Student_datePicker__3ZvEY :hover {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .Student_student__1sf91 {
    margin: auto !important;
  }
}
@media (max-width: 426px) {
  .Student_row__1egmA {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

 .ParentBox_parent__BsX1K {
  margin: 30px 0;
}
.ParentBox_input__G15LP {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  /* width: auto !important; */
}
.ParentBox_input__G15LP:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media (max-width: 426px) {
  .ParentBox_row__1Qzux {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.AddressBox_addressBox__2KJk1 {
  border-bottom: 1px solid #00a568;
  border-left: 1px solid #00a568;
  border-right: 1px solid #00a568;
}

.AddSibling_title__25Sp7 {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.AddSibling_button__19fxh {
  background-color: #00a568;
  border: none !important;
}
.AddSibling_button__19fxh:hover {
  background-color: #017c4f;
}
.AddSibling_button__19fxh:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}
.AddSibling_row__1P0x6 {
  width: 100% !important;
}
.AddSibling_input__2WZuZ {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  /* width: auto !important; */
}
.AddSibling_input__2WZuZ:focus {
  background-color: transparent !important;
  box-shadow: none !important;
}

@media (max-width: 769px) {
  .AddSibling_title__25Sp7 {
    font-size: 20px;
    padding: 15px;
  }
  .AddSibling_row__1P0x6 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.SiblingDetails_title__29NUm {
  width: -webkit-max-content;
  width: max-content;
  background-color: #00a568;
  color: white;
  font-size: 20px;
}
.SiblingDetails_info__3EZbv {
  border: 1px solid #00a568;
  position: relative;
}
.SiblingDetails_iconBox__XzYi- {
  background-color: #00a568;
  padding: 9px;
  -webkit-align-self: flex-end;
          align-self: flex-end;
}
.SiblingDetails_icons__93__d {
  color: white;
  margin: 0 6px;
}
.SiblingDetails_icons__93__d:hover {
  color: #000;
  cursor: pointer;
}

.SiblingDetails_row__7JJsG {
  margin: 0;
}
.SiblingDetails_row__7JJsG div {
  margin: 10px auto !important;
}
@media (max-width: 426px) {
  .SiblingDetails_row__7JJsG {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.OuterDiv_outerDiv__YE4GA {
  background-image: url(/static/media/bg.79462d8b.png);
  margin: auto;
  background-color: white;
  position: relative;
  z-index: 99;
}

.InnerDiv_innerDiv__F_9Up {
  background-color: #ffff;
  width: 60%;
  margin: auto;
  position: relative;
  z-index: 99;
}
@media (max-width: 1025px) {
  .InnerDiv_innerDiv__F_9Up {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .InnerDiv_innerDiv__F_9Up {
    width: 90%;
  }
}

.ReviewInfo_title__1-AkX {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.ReviewInfo_heading__Yabmk {
  background-color: #00a568;
  color: white;
  font-size: 20px;
  padding: 20px;
  width: -webkit-max-content;
  width: max-content;
}
.ReviewInfo_info__20fVB {
  border: 1px solid #00a568;
  position: relative;
}
/* .footer {
  position: fixed;
  bottom: 20px;
} */

@media (max-width: 769px) {
  .ReviewInfo_title__1-AkX {
    font-size: 20px;
    padding: 15px;
  }
}

.Spinner_spinnerDiv__HBjTf {
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(0, 0, 0, 0.7); */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #00a568;
  position: fixed;
  z-index: 999;
}
.Spinner_spinner__35ZoF {
  height: 200px !important;
  width: 200px !important;
}


.RegistrationForm_container__3c27H {
  padding: 20px 0;
  background-color: white;
  /* padding: 40px; */
}
.RegistrationForm_addressBox__2lz5Z {
  border-bottom: 1px solid #00a568;
  border-left: 1px solid #00a568;
  border-right: 1px solid #00a568;
}

.RegistrationForm_button__2yYnG {
  background-color: #00a568;
  border: none !important;
}
.RegistrationForm_button__2yYnG:hover {
  background-color: #017c4f;
}
.RegistrationForm_button__2yYnG:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}

.AdminSearch_row__18nr4 {
    border-right: 1px solid #00a568;
    border-left: 1px solid #00a568;
    border-bottom: 1px solid #00a568;
    padding-bottom: 20px;
}
.AdminSettings_container__136pT {
  margin-top: 85px;
}
.AdminSettings_div__2ZKFM {
  padding: 50px;
}
.AdminSettings_textBox__2PZi1:focus {
  box-shadow: none;
}
@media (max-width: 768px) {
  .AdminSettings_notification__1_CNc {
    width: 100%;
  }
}

.AdminSettings_Sidebar_sidebar__1DJX7 {
  height: 100vh;
  margin-top: 83px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}
@media (max-width: 426px) {
  .AdminSettings_Sidebar_sidebar__1DJX7 {
    height: auto !important;
  }
}

.AdminCard_card__1hfHF {
    padding: 50px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 8px 6px rgb(0 0 0 / 2%);
}
.AdminDashboard_col__uBFlH {
  /* height: 100%;  */
  padding-top: 12px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 8px 6px rgb(0 0 0 / 2%);
}
.AdminDashboard_mainRow__mR6qK {
  padding: 12px;
}
@media (max-width : 426px) {
  .AdminDashboard_row__3-1I_ {
    text-align: center;
  }
  .AdminDashboard_row__3-1I_ > div {
    margin: 10px auto;
  }
  .AdminDashboard_col__uBFlH {
    margin: 10px auto;
  }
  .AdminDashboard_col__uBFlH > h3 {
    margin: 20px auto;
    text-align: center;
  }
  .AdminDashboard_mainRow__mR6qK {
    padding: 0px;
  }
}

.AdminStudentEntry_row__cCIK9 {
  border-right: 1px solid #00a568;
  border-left: 1px solid #00a568;
  padding-bottom: 20px;
}
.AdminStudentEntry_form__2Us7t {
  width: 100%;
}
.AdminStudentEntry_addressBox__EwK8w {
  border-bottom: 1px solid #00a568;
  border-left: 1px solid #00a568;
  border-right: 1px solid #00a568;
}

.AdminSchoolUpload_heading__qHCyT {
  background-color: #00a568;
  color: white;
  font-size: 25px;
  padding: 20px;
}
.AdminSchoolUpload_column__3VWaf {
  border: 1px solid #00a568;
  padding: 10px 0;
}

