.title {
  width: max-content;
  background-color: #00a568;
  color: white;
  font-size: 20px;
}
.student {
  border-top: 1px solid #00a568;
  border-right: 1px solid #00a568;
  border-left: 1px solid #00a568;
}
.input {
  border-top: none !important;
  border-left: none;
  border-right: none;
  border-radius: 0;
  background-color: transparent;
  position: relative;
}
.input:focus {
  background-color: transparent;
  box-shadow: none;
}
.datePicker {
  padding: 0.375rem 0 !important;
  background-color: transparent;
}

/* --------------------------------------------------------------------------------- */

.css-1480iag-MuiInputBase-root-MuiInput-root::after {
  border-radius: 4px !important;
  position: relative !important;
  border: 1px solid #ced4da !important;
  font-size: 16px !important;
  width: auto !important;
  padding: 10px 12px !important;

  border-bottom: 1px solid yellow !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  /* -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0); 
  -ms-transform: scaleX(0); */
  transform: scaleX(0);
  /* -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
.css-1480iag-MuiInputBase-root-MuiInput-root:focus {
  border-bottom: 1px solid yellow !important;
}

/* --------------------------------------------------------------------------------------- */
.datePicker :hover {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .student {
    margin: auto !important;
  }
}
@media (max-width: 426px) {
  .row {
    flex-direction: column;
  }
}
