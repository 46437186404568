.dropdown {
  /* width: 234px; */
  margin: 0 10px;
}
.select {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
}

.select:disabled {
  color: #000 !important;
  background-color: lightgray !important;
  opacity: 0.5 !important;
}
.select:focus {
  box-shadow: none !important;
  background-color: transparent !important;
}
@media (max-width: 769px) {
  .dropdown {
    margin: 10px;
  }
}
