
.container {
  padding: 20px 0;
  background-color: white;
  /* padding: 40px; */
}
.addressBox {
  border-bottom: 1px solid #00a568;
  border-left: 1px solid #00a568;
  border-right: 1px solid #00a568;
}

.button {
  background-color: #00a568;
  border: none !important;
}
.button:hover {
  background-color: #017c4f;
}
.button:focus {
  background-color: #017c4f !important;
  box-shadow: none !important;
}
