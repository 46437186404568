.input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    position: relative !important;
}
.input:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}